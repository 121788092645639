/* eslint-disable*/
import { createTheme } from '@material-ui/core/styles';
import ErrorIcon from './assets/images/Error-Icon.svg';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 900,
      lg: 1200,
      xl: 1200,
    },
  },
  // Color style
  palette: {
    primary: {
      main: '#486877',
      contrastText: '#fff',
    },
    secondary: {
      main: '#C72943',
      contrastText: '#fff',
    },
    success: {
      main: '#2E7D32',
      // dark: '#1B5E20',
      // light: '#4CAF50',
      contrastText: '#fff',
      // backgroundColor: '#EDF7ED',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hover: 'rgba(0, 0, 0, 0.08)',
    },
  },
  // Fonts style
  typography: {
    fontFamily: ['Urbanist', 'sans-serif'].join(','),
    h1: {
      fontSize: 36,
      fontWeight: 400,
      // color: '#486877',
      wordBreak: 'break-word',
      fontFamily: ['Arima', 'cursive'].join(','),
      '@media (max-width: 1024px)': {
        fontSize: 32,
      },
      '@media (max-width: 767px)': {
        fontSize: 32,
      },
    },
    h2: {
      fontSize: 32,
      fontWeight: 600,
      color: '#486877',
      wordBreak: 'break-word',
      fontFamily: ['Arima', 'cursive'].join(','),
      '@media (max-width: 1024px)': {
        fontSize: 24,
      },
      '@media (max-width: 767px)': {
        fontSize: 24,
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      // color: '#486877',
      wordBreak: 'break-word',
      fontFamily: ['Arima', 'cursive'].join(','),
      '@media (max-width: 1024px)': {
        fontSize: 20,
      },
      '@media (max-width: 767px)': {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
      // color: '#486877',
      wordBreak: 'break-word',
      '@media (max-width: 1024px)': {
        fontSize: 18,
      },
      '@media (max-width: 767px)': {
        fontSize: 18,
      },
    },
    h5: {
      fontSize: 18,
      fontWeight: 700,
      // color: '#486877',
      wordBreak: 'break-word',
      '@media (max-width: 1024px)': {
        fontSize: 16,
      },
      '@media (max-width: 767px)': {
        fontSize: 16,
      },
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      // color: '#486877',
      wordBreak: 'break-word',
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 700,
      color: '#4e5054',
    },
    body1: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '26px',
      color: 'rgba(0, 0, 0, 0.87);',
      wordBreak: 'break-word',
      // letterSpacing: "2px",
      '@media (max-width: 767px)': {
        fontSize: 16,
      },
    },
    body2: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '22px',
      color: 'rgba(0, 0, 0, 0.6);',
      wordBreak: 'break-word',
      // letterSpacing: "2px",
      '@media (max-width: 1024px)': {
        fontSize: 14,
      },
      '@media (max-width: 767px)': {
        fontSize: 14,
      },
    },
    caption: {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.6);',
      wordBreak: 'break-word',
      '@media (max-width: 1024px)': {
        fontSize: 13,
      },
      '@media (max-width: 767px)': {
        fontSize: 13,
      },
    },
  },
  // Button style
  // forms style
  // Overrides style - button, forms

  overrides: {
    MuiPaper: {
      /* root: {
        backgroundColor: '#e5ebf1',
      }, */
    },
    MuiList: {
      /* root: {
        margin: '0 8px',
      }, */
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none',
        /* borderRight: '10px solid rgba(243, 239, 234, 1)',
        boxShadow: '3px 0 5px 0 rgba(0,0,0,0.1)', */
      },
    },
    MuiFormHelperText: {
      root: {
        '&.$Mui-error': {
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '14px',
          backgroundImage: `url(${ErrorIcon})`,
          backgroundSize: '17px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left 0px',
          marginLeft: '0',
          paddingLeft: '24px',
          marginTop: '8px',
        },
      },
    },

    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '22px',
      },
    },

    MuiDivider: {
      root: {
        marginTop: '10px',
        marginBottom: '10px',
        borderBottom: '1px solid #E2E1D5',
        height: 'auto',
        background: 'none',
      },
    },

    MuiButton: {
      root: {
        borderRadius: 50,
        padding: '5px 15px',
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 1,
        minWidth: 160,
        textTransform: 'uppercase',
        minHeight: '44px',
        letterSpacing: '1.5px',
        '&.$Mui-disabled': {
          backgroundColor: '#E2E1D5!important',
          fontWeight: 600,
          color: 'rgba(0, 0, 0, 0.38)!important',
        },

        '@media (max-width:1024px)': {
          fontSize: 16,
        },
      },

      sizeSmall: {
        fontSize: '16px',
        fontWeight: '600',
        padding: '8px 10px',
        '@media (max-width:1024px)': {
          fontSize: '14px',
        },
      },
      SizeMediuml: {
        fontSize: '18px',
        '@media (max-width:1024px)': {
          fontSize: '16px',
        },
      },
      sizeLarge: {
        fontSize: '24px',
        fontWeight: '700',
        '@media (max-width:1024px)': {
          fontSize: '20px',
        },
      },
      contained: {
        color: '#FFFFFF',
        backgroundColor: 'rgba(162, 156, 153, 1)',
        minWidth: '120px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#569CA4',
          color: '#fff',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#569CA4',
          color: '#fff',
          boxShadow: 'none',
        },
        // "&:focus": {
        //   color: "#FFFFFF",
        //   backgroundColor: "#486877",
        //   border: "3px solid #95C8C2",
        // },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#E57184',
          color: '#fff',
          boxShadow: 'none',
        },
        // "&:focus": {
        //   color: "#FFFFFF",
        //   backgroundColor: "#C72943",
        //   border: "4px solid #FFCCD5",
        // },
      },
      outlined: {
        boxShadow: 'none',
        color: '#486877',
        backgroundColor: '#fff',
        minWidth: 'auto',
        border: '1px solid #486877',
        minHeight: '44px',
        '&:hover': {
          backgroundColor: '#fff',
          color: '#569CA4',
          borderColor: '#569CA4',
        },
      },
      outlinedPrimary: {
        color: '#486877',
        border: '1px solid #486877',
        '&:hover': {
          backgroundColor: '#fff',
          color: '#569CA4',
          borderColor: '#569CA4',
        },
        // "&:focus": {
        //   color: "#486877",
        //   backgroundColor: "#FFFFFF",
        //   border: "3px solid #486877",
        // },
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected': { backgroundColor: 'transparent' },
      },

      button: {
        '&:hover': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
        '&:active': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
      },
      gutters: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#FFF',
        minWidth: 'auto',
        padding: '5px 0',
        /* border: '1px solid #fff',
        borderRadius: '5px', */
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.87)',
        lineHeight: 1.5,
        fontWeight: '600',
        fontFamily: ['Urbanist', 'serif'].join(','),
        '&.$Mui-disabled': {
          color: '#4e5054',
        },
        '@media (max-width:1024px)': {
          fontSize: 14,
          fontWeight: '700',
        },
      },
      shrink: {
        fontSize: 16,
        color: '#4B403A',
        fontWeight: 700,
      },
      outlined: {
        transform: 'translate(10px, 7px) scale(1)',
      },
    },

    MuiOutlinedInput: {
      root: {
        border: '1px solid #E2E1D5',
        borderRadius: 8,
        backgroundColor: '#fff',
        '& $notchedOutline': {},
        '&:hover $notchedOutline': {
          borderColor: '#95C8C2',
          boxShadow: '0px 1px 10px #95C8C2',
        },
        '&$focused $notchedOutline': {
          borderColor: '#95C8C2',
          boxShadow: '0px 1px 10px #95C8C2',
        },
        '&$error $notchedOutline': {
          borderColor: '#C72943',
          boxShadow: 'none',
        },
      },
      input: {
        padding: '8px 8px',
        minHeight: '24px',
      },
      inputMultiline: {
        borderRadius: 15,
        padding: '10px 15px',
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
      },
      multiline: {
        padding: 0,
      },
      notchedOutline: {
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
        },
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },

    MuiAutocomplete: {
      option: {
        fontSize: '16px',
        '&:hover': {
          backgroundColor: '#FFF2E0',
        },
        '&$checked': {
          backgroundColor: '#FFF2E0',
        },
        '&[data-focus="true"]': {
          backgroundColor: '#FFF2E0',
        },
        '&[aria-selected="true"]': {
          backgroundColor: '#FFF2E0',
        },
      },
      inputRoot: {
        padding: '0 !important',
      },
      input: {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '8px 66px 8px 12px !important',
        // "&:first-child": {
        //   paddingLeft: "6px !important",
        // },
      },
    },

    // Mui: {
    //   Disabled: {
    //     fontSize: 56,
    //     backgroundColor: '#000',
    //   },
    // },

    MuiInputBase: {
      input: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: '#fff',
        borderRadius: 15,
        fontWeight: 500,
        padding: 10,
        '&.$Mui-disabled': {
          backgroundColor: 'rgba(226, 225, 213, 0.3)',
          borderRadius: 8,
        },
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
      multiline: {
        // border: '2px solid rgba(162,156,153,0.2)',
        // borderBottom: 'none',
        fontSize: 16,
        color: '#4B403A',
        backgroundColor: '#fff',
        borderRadius: 15,
        fontWeight: 400,
        // '&.$Mui-disabled': {
        //   backgroundColor: 'rgba(0, 0, 0, 0.1)',
        // },
      },
    },
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: '0px solid rgba(187, 187, 187, 1)',
        },
        '&&&&:after': {
          borderBottom: '0px solid rgba(187, 187, 187, 1)',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        display: 'flex',
      },
      label: {
        fontSize: 16,
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    // MuiDialogContent: {
    //   root: {
    //     border: 'none',
    //   },
    // },
    MuiDialog: {
      paper: {
        backgroundColor: '#fdfeff',
      },
    },
    MuiAlert: {
      root: {
        color: 'rgba(0, 0, 0, 0.6)',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
      },
      filledSuccess: {
        color: 'rgba(0, 0, 0, 0.6)',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
      },
    },

    MuiSelect: {
      icon: {
        color: '#5A908F',
        right: '5px',
      },
      outlined: {
        borderRadius: 8,
      },
      select: {
        borderRadius: 8,
        padding: '10px',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        boxShadow: 'none',
        '&:focus': {
          borderRadius: 8,
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: 'rgba(0, 0, 0, 0.6)',
        padding: '4px 9px',
      },
    },
    MuiRadio: {
      root: {
        color: 'rgba(0, 0, 0, 0.6)',
        padding: '4px 9px',
      },
    },

    MuiIconButton: {
      root: {
        color: '#486877',
      },
      colorInherit: {
        color: '#486877',
      },
      colorSecondary: {
        color: '#000',
      },
    },

    MuiAvatar: {
      img: {
        width: '100%',
        height: 'auto',
      },
    },

    // Right Panel
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: '#4B403A',
        color: '#ffffff',
      },
    },

    MuiExpansionPanelDetails: {
      root: {
        padding: '10px 0px 10px 0px',
      },
    },

    // Datepicker
    MuiPickersBasePicker: {
      pickerView: {
        width: 'auto',
        height: 'auto',
        maxWidth: 'auto',
        minWidth: 'auto',
        minHeight: 'auto',
        padding: '10px 20px 10px 20px',
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: '15px',
        marginBottom: '30px',
      },
    },

    // Chip

    MuiChip: {
      root: {
        boxShadow: 'none',
        fontSize: '14px',
        fontWeight: '500',
        backgroundColor: 'rgba(229, 239, 235, 1)',
        border: '1px solid #486877',
      },
      sizeSmall: {
        fontSize: '13px',
      },
      label: {
        color: '#486877',
      },
      deleteIcon: {
        color: '#486877',
      },
    },

    // Switch
    MuiSwitch: {
      root: {
        width: 41,
        height: 22,
        padding: 0,
        display: 'flex',
      },

      switchBase: {
        padding: 4,
        // color: '#A29C99',
        '&$checked': {
          transform: 'translateX(0px)',
          // color: '#FF7412!important',

          '& + $track': {
            opacity: 1,
            backgroundColor: '#fff!important',
            // borderColor: '#FF7412',
            // color: '#FF7412!important',
          },
        },
      },

      thumb: {
        width: 13,
        height: 13,
        boxShadow: 'none',
      },

      track: {
        border: '2px solid #A29C99',
        borderRadius: 30 / 2,
        opacity: 1,
        backgroundColor: '#fff',
      },
    },

    // Overrides style - modal dialog

    MuiDialogTitle: {
      root: {
        margin: 0,
        padding: '16px',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },

    MuiDialogContent: {
      root: {
        overflow: 'auto',
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },

    MuiDialogActions: {
      spacing: {
        paddingTop: '16px',
        paddingBottom: '30px',
        paddingLeft: '16px',
        paddingRight: '16px',
        justifyContent: 'center',
      },
    },

    // Chat

    MuiListItemText: {
      root: {
        width: 'inherit',
        marginLeft: 20,
      },
      primary: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#486877',
        textTransform: 'uppercase',
      },
    },

    // Overrides style - material ui data table

    MuiTableCell: {
      root: {
        fontSize: 11,
        color: '#4e5054',
        fontWeight: '700',
        borderBottom: '2px solid rgba(243, 239, 234, 1)',
        wordBreak: 'break-word',
        padding: '12px',
        '&:last-child': {
          paddingRight: '12px!important',
          width: '96px!important',
        },
      },

      head: {
        backgroundColor: '#f2f5f8 !important',
        color: '#4e5054',
        fontWeight: '700',
        fontSize: 11,
        whiteSpace: 'nowrap',
      },
    },

    MuiTableRow: {
      root: {
        backgroundColor: 'rgba(255,255,255,1)',
        fontSize: 11,
        color: '#19191a',
        fontWeight: '400',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#e2ddd8',
          color: '#4B403A',
        },
      },
      footer: {
        backgroundColor: '#f2f5f8',
        '&:hover': {
          backgroundColor: '#f2f5f8',
        },
      },
    },

    MuiTablePagination: {
      select: {
        backgroundColor: 'transparent',
        border: '0px solid #c4c4c4',
        boxShadow: '0px 0px 0px rgba(162,156,153,0.7)',
        lineHeight: '20px',
        color: '#4e5054',
        fontSize: 11,
        fontWeight: '400',
      },

      root: {
        '&:last-child': {
          width: '100%!important',
        },
      },
    },

    MuiBreadcrumbs: {
      separator: {
        color: '#809db7',
        fontSize: '18px',
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: 'none',
        backgroundColor: '#e5ebf1',
        margin: '16px 0',
        '&&&&:before': {
          display: 'none',
        },
      },
      rounded: {
        borderRadius: 4,
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: 0,
      },
    },
    MuiTabPanel: {
      root: {
        padding: 0,
      },
    },
    MuiTab: {
      root: {
        minWidth: 'auto !important',
        fontWeight: '500',
        overflow: 'visible',
        padding: 0,
        marginRight: '20px',
        textTransform: 'capitalize',
        fontSize: '16px',
      },
      textColorInherit: {
        color: 'rgba(0, 0, 0, 0.6)',
        opacity: '1',
        '&.Mui-selected': {
          color: '#486877',
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: '2px',
        backgroundColor: '#D4A569',
        borderRadius: '1px',
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: '#D68649',
      },
      anchorOriginTopRightRectangle: {
        right: '-15px',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
});

export default theme;
