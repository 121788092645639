/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  Link,
  InputLabel,
  Container,
  InputAdornment,
} from '@material-ui/core';
import { Formik, Form, FastField, useFormik } from 'formik'; // Import useFormik
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ReactComponent as MapPinIcon } from '../../../../assets/images/MapPin.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/images/PhoneIcon.svg';
import { ReactComponent as WebsiteIcon } from '../../../../assets/images/WebsiteIcon.svg';
import useCommunityList from '../../../../hooks/useCommunityList';
import { useNavigate, useParams } from 'react-router';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import NumberFormat from 'react-number-format';
import FacilityDeleteModal from '../CommunityHealthFacility/FacilityDeleteModal';
import ModalEditViewDelete from '../../ModalEditViewDelete/ModalEditViewDelete';
import * as Yup from 'yup';

const facilityTypeOptions = [
  // Define your facility type options
  { title: 'Assisted Living Facility' },
  { title: 'Behavioral Health Facility' },
  { title: 'Home Healthcare' },
  { title: 'Hospice Services' },
  { title: 'Hospital - General Acute Care' },
  { title: 'Hospital - Specialty Care' },
  { title: 'Long Term Care Facility' },
  { title: 'OutPatient Specialty Care Facility' },
  // Add more options as needed
];

const CommunityAddHealthFacility = () => {
  const navigate = useNavigate();
  //console.log(isEdit, 'EM');
  const [isAccessible, setIsAccessible] = React.useState('');

  const {
    addCommunityHealthFacility,
    getCommunityHealthFacility,
    updateCommunityHeathFacility,
    getByFacilityId,
    getCommunityDetailById,
  } = useCommunityList();

  const params = useParams();
  //console.log(params?.facilityId, 'params');
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getFacilityId, setFacilityId] = useState(null);
  const [compRepId, setCompRepId] = useState(null);

  //console.log(compRepId && compRepId[0] && compRepId[0].repId, 'compRepId');

  const key = 'iFrasCwdJh';
  let bytes = null;

  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    window.location.hash = '#/app/home-page';
  }

  const handleBackToParent = (e) => {
    e.preventDefault();
    if (formik.dirty) {
      setShowDeleteBox1(true);
    } else {
      navigate(`/app/community-details/${params.id}#Section11`);
    }
  };

  const onTakeMeback = (event) => {
    setShowDeleteBox1(false);
  };

  //console.log(params?.facilityId, 'PFD');

  // const fetchComRepId = async () => {
  //   const repId = await getByFacilityId({
  //     facilityId: params?.facilityId,
  //   });

  //   setCompRepId(repId.data);
  // };

  // useEffect(() => {
  //   fetchComRepId();
  // }, []);

  const fetchData = async () => {
    try {
      const response = await getCommunityHealthFacility({
        communityId: params?.id,
        // repId: compRepId && compRepId[0] && compRepId[0].repId,
        facilityId: params?.facilityId,
      });
      //console.log(response);
      // Assuming the response contains an array of health facilities
      setInitialValues(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setIsEdit(params.facilityId != undefined ? true : false);
    if (compRepId && compRepId[0] && compRepId[0].repId) {
      setIsEdit(params.facilityId != undefined ? true : false);
      if (params.facilityId) {
        fetchData();
      } else {
        setInitialValues({
          facilityType: '', // Add the default value if not present in the response
          // other fields...
        });
      }
    } else {
      // Log a message or handle accordingly when compRepId is not yet available
      //console.log('Waiting for compRepId to be available...');
    }
  }, [compRepId, params?.id, params?.facilityId]);
  //console.log(initialValues, 'HFHF');

  useEffect(() => {
    fetchData(); // Call the fetchData function when the component mounts
  }, [params?.facilityId]);

  const handleSubmit = (values) => {
    //console.log('Form values:', values);
    if (values.facilityPhone === '') {
      // If empty, set it to null
      values.facilityPhone = null;
    }
    if (isEdit) {
      // Handle update logic here
      values['isAdminEntry'] = Boolean(loggedInUserData?.roleId === 2);
      updateCommunityHeathFacility(initialValues.id, values)
        .then((response) => {
          // Handle success
          //console.log('Updated facility:', response);

          setTimeout(() => {
            navigate(`../../../community-details/${params?.id}#Section11`);
          }, 300);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating facility:', error);
        });
    } else {
      // Handle add logic here
      values['isAdminEntry'] = Boolean(loggedInUserData?.roleId === 2);
      addCommunityHealthFacility(values)
        .then((response) => {
          // Handle success
          //console.log('Added facility:', response);
          setTimeout(() => {
            navigate(`../../community-details/${params?.id}#Section11`);
          }, 1000);
        })
        .catch((error) => {
          // Handle error
          console.error('Error adding facility:', error);
        });
    }
  };

  const [formattedPhone, setFormattedPhone] = useState(
    isEdit ? initialValues?.facilityPhone || '' : '',
  );

  const validationSchema = Yup.object().shape({
    facilityType: Yup.string().required('Facility Type is required'),
    facilityName: Yup.string().required('Facility Name is required'),
    facilityAddress: Yup.string().required('Facility Address is required'),
    facilityPhone: Yup.string()
      .nullable()
      // .matches(/^\d{10}$/, 'Phone Number must be 10 digits'),
      .matches(/^[1-9]\d{9}$/, 'Phone Number must not start with 0 and must be 10 digits'),
    facilityWebUrl: Yup.string()
      .trim()
      .matches(
        /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/,
        'Facility URL must be a valid web address.',
      )
      .max(2083, 'URL is too long.')
      .nullable(),
  });

  const handlePhoneChange = (event) => {
    const inputPhoneNumber = event.target.value;
    // Remove all non-numeric characters from the input
    const cleanedPhoneNumber = inputPhoneNumber.replace(/\D/g, '');

    // Limit the phone number to 10 digits
    const truncatedPhoneNumber = cleanedPhoneNumber.slice(0, 10);

    // Format the phone number as (XXX) XXX-XXXX
    const formattedPhoneNumber = truncatedPhoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '($1) $2-$3',
    );

    // Update the state with the formatted phone number
    setFormattedPhone(formattedPhoneNumber);

    // Update Formik's field value with the cleaned phone number (without formatting)
    formik.setFieldValue('facilityPhone', truncatedPhoneNumber);
  };
  // Initialize useFormik hook
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      communityId: params?.id,
      facilityId: params?.facilityId,
      repId: loggedInUserData?.id,
      facilityType: isEdit ? initialValues?.facilityType : '', // Initialize facilityType
      facilityName: isEdit ? initialValues?.facilityName : '',
      facilityAddress: isEdit ? initialValues?.facilityAddress : '',
      facilityPhone: isEdit ? initialValues?.facilityPhone : '', // Initialize with an empty string
      facilityWebUrl: isEdit ? initialValues?.facilityWebUrl : '',

      // Add other fields here
    },
    validationSchema,
    onSubmit: handleSubmit,
  });
  //console.log(isEdit, 'EMEME');
  useEffect(() => {
    if (isEdit && initialValues && initialValues.facilityPhone) {
      // Format the phone number as (XXX) XXX-XXXX
      const formattedPhoneNumber = initialValues.facilityPhone.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '($1) $2-$3',
      );
      setFormattedPhone(formattedPhoneNumber);
    }
  }, [isEdit, initialValues]);

  const [showDeleteBox, setShowDeleteBox] = useState(false);

  const [showDeleteBox1, setShowDeleteBox1] = useState(false);

  const openModal = () => {
    setShowDeleteBox(true);
    setFacilityId(params?.facilityId);
  };

  //console.log(getFacilityId, 'GETFD');

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSuccessfulDelete = () => {
    // This function will be called when the delete operation is successful
    // You can trigger any necessary actions here
    // For example, fetching data if needed
    fetchData();
  };

  useEffect(() => {
    loggedInUserData?.roleId === 1 || loggedInUserData?.roleId === 2
      ? setIsAccessible(true)
      : setIsAccessible(false);
  }, [loggedInUserData]);

  return (
    <Container>
      {isAccessible ? (
        <>
          <Box className="PageSpacing FixedMD CommunityAddHealthFacility">
            {showDeleteBox1 && (
              <>
                <ModalEditViewDelete
                  status={true}
                  navigateUrl={`/app/community-details/${params.id}#Section11`}
                  onClose={onTakeMeback}
                ></ModalEditViewDelete>
              </>
            )}
            <Grid container>
              <Grid item xs={12}>
                <Link
                  underline="none"
                  onClick={(e) => {
                    handleBackToParent(e);
                  }}
                  className="BackButtonWrapper"
                >
                  <Typography className="BackButton" variant="body1">
                    <ArrowBackIcon color="primary" className="BackButtonArrow" />
                    {isEdit ? 'Edit Facility' : 'Add Facility'}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container className="FixedID">
              <Grid item xs={12}>
                <Formik
                  enableReinitialize={true} // Allow form reinitialization
                  initialValues={formik.values} // Use formik.values for initialization
                  onSubmit={formik.handleSubmit} // Use formik.handleSubmit for submission
                >
                  {({ handleSubmit, handleChange, values, errors, touched, dirty }) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} className="commonFieldBox">
                          <InputLabel className="InputLabelFld" htmlFor="facilityType">
                            Type
                          </InputLabel>
                          <Autocomplete
                            className="CommonAutocomplete"
                            id="facilityType"
                            options={facilityTypeOptions}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Facility Type"
                                label=""
                                variant="outlined"
                              />
                            )}
                            value={{ title: formik.values.facilityType }}
                            onChange={(event, newValue) => {
                              formik.setFieldValue('facilityType', newValue ? newValue.title : '');
                            }}
                            popupIcon={<KeyboardArrowDownIcon />}
                          />
                          {/* {formik.errors.facilityType && formik.touched.facilityType && (
                        <div className="ErrorMessage">{formik.errors.facilityType}</div>
                      )} */}
                        </Grid>
                        <Grid item xs={12} sm={6} className="commonFieldBox">
                          <InputLabel className="InputLabelFld" htmlFor="facilityName">
                            Facility Name
                          </InputLabel>
                          <FastField
                            as={TextField}
                            name="facilityName"
                            id="facilityName"
                            variant="outlined"
                            type="text"
                            fullWidth
                            onChange={(event) => {
                              formik.setFieldValue('facilityName', event.target.value); // Update facilityName
                            }}
                          />
                          {/* {formik.errors.facilityName && formik.touched.facilityName && (
                        <div className="ErrorMessage">{formik.errors.facilityName}</div>
                      )} */}
                        </Grid>
                        <Grid item xs={12} sm={6} className="commonFieldBox">
                          <InputLabel className="InputLabelFld" htmlFor="facilityAddress">
                            Address
                          </InputLabel>
                          <FastField
                            as={TextField}
                            name="facilityAddress"
                            id="facilityAddress"
                            variant="outlined"
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MapPinIcon />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            onChange={(event) => {
                              formik.setFieldValue('facilityAddress', event.target.value); // Update facilityAddress
                            }}
                          />
                          {/* {formik.errors.facilityAddress && formik.touched.facilityAddress && (
                        <div className="ErrorMessage">{formik.errors.facilityAddress}</div>
                      )} */}
                        </Grid>
                        <Grid item xs={12} sm={6} className="commonFieldBox">
                          <Box display="flex" justifyContent="space-between">
                            <InputLabel className="InputLabelFld" htmlFor="facilityPhone">
                              Phone Number
                            </InputLabel>
                            <Typography variant="caption" component="div">
                              Optional
                            </Typography>
                          </Box>
                          <TextField
                            name="facilityPhone"
                            id="facilityPhone"
                            variant="outlined"
                            type="text"
                            fullWidth
                            value={formattedPhone}
                            onChange={handlePhoneChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PhoneIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {formik.errors.facilityPhone && formik.touched.facilityPhone && (
                            <div className="ErrorMessage">{formik.errors.facilityPhone}</div>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box display="flex" justifyContent="space-between">
                            <InputLabel className="InputLabelFld" htmlFor="facilityWebUrl">
                              Website{' '}
                              <Typography component="span" variant="body2">
                                <Box component="span" fontWeight="600">
                                  (Enter URL)
                                </Box>
                              </Typography>
                            </InputLabel>
                            <Typography variant="caption" component="div">
                              Optional
                            </Typography>
                          </Box>
                          <FastField
                            as={TextField}
                            name="facilityWebUrl"
                            id="facilityWebUrl"
                            variant="outlined"
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <WebsiteIcon />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            onChange={(event) => {
                              formik.setFieldValue('facilityWebUrl', event.target.value || ''); // Update facilityWebUrl
                            }}
                          />
                          {formik.errors.facilityWebUrl && formik.touched.facilityWebUrl && (
                            <div className="ErrorMessage">{formik.errors.facilityWebUrl}</div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="Wrapper ButtonWrapper">
                            <Button
                              disabled={
                                !formik.values.facilityType ||
                                !formik.values.facilityName ||
                                !formik.values.facilityAddress ||
                                !formik.dirty ||
                                (!formik.values.facilityType &&
                                  !formik.values.facilityName &&
                                  !formik.values.facilityAddress)
                              }
                              // disabled={
                              //   (!formik.dirty && isEdit) || // Disable if not edited
                              //   (!values.facilityType || !values.facilityName || !values.facilityAddress) // Disable if any of the fields is empty
                              // }
                              variant="contained"
                              color="primary"
                              type="submit"
                              // onClick={updateFacility}
                              className="EditViewButton"
                              fullWidth
                            >
                              {isEdit ? 'Update' : 'Publish'}
                            </Button>
                          </Box>
                          {/* Add Delete Facility button */}
                          <Box component="div" display="flex" justifyContent="center">
                            {isEdit ? (
                              <>
                                <>
                                  <Button
                                    onClick={openModal}
                                    underline="none"
                                    className="DeleteAmenityButton LinkTypeButton RedLineButton"
                                    color="secondary"
                                    variant="text"
                                  >
                                    Delete Facility
                                  </Button>
                                </>
                                {showDeleteBox && (
                                  <FacilityDeleteModal
                                    open={isModalOpen}
                                    onCloseParent={closeModal}
                                    setShowDeleteBox={setShowDeleteBox}
                                    getFacilityId={getFacilityId}
                                    fetchData={fetchData}
                                    onSuccessfulDelete={handleSuccessfulDelete}
                                    // other props...
                                  />
                                )}

                                {/* Add Delete Facility button */}
                              </>
                            ) : (
                              ''
                            )}
                          </Box>
                        </Grid>

                        {/* <FacilityDeleteModal></FacilityDeleteModal> */}
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        'You do not have permissions to access this url.'
      )}
    </Container>
  );
};

export default CommunityAddHealthFacility;
